

































































import { Component, Vue } from 'vue-property-decorator'
import VsInputPassword from '@/components/VsInputPassword/Index.vue'
import { activateUser, sendActivationEmail } from '@/api/userapi/users'
import { AppModule } from '@/store/modules/app'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'
import { getIsLegacyUser, getShopUser } from '@/api/shop'

@Component({
    name: 'Registration',
    components: {
        VsInputPassword,
    },
})
export default class extends Vue {
    // TODO: questa pagina non è whitelabel registrazione e attivazione non sono whitelabel
    // ci sono riferimenti a 4dem ed alla privacy di 4dem
    private loading = false
    emailBtnEnabled = true
    count = 60
    private otp = ''

    $refs: any

    get siteUrl () {
        return AppModule.siteUrl
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    private setFocus () {
        if (typeof this.$refs.autofocus !== 'undefined') {
            this.$refs.autofocus.$children[0].$refs.input.focus()
        }
    }

    private openContact () {
        if (window.Beacon) {
            window.Beacon('open')
        } else {
            window.open(this.siteUrl + '/contatta-4dem/', '_blank')
        }
    }

    private async activateUser () {
        const valid = await this.$refs.confirmUser.validate()
        if (!valid) return

        this.loading = true
        try {
            const resp = await activateUser({ otpSignup: this.otp })
            let shopUser = null
            try {
                const response = await getShopUser()
                shopUser = response.data
            } catch (e) {

            }
            const isLegacyUser = await getIsLegacyUser()
            UserModule.loginUser({ user: resp.data, shopUser, isLegacyUser })
            await UserModule.setUserSettings(AppModule.consoleConf)
            this.$router.replace({
                name: 'activationIndex',
            })
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    this.$refs.confirmUser.setErrors({
                        otp: ['Il codice otp inserito non è valido'],
                    })
                }
            }
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore di attivazione',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }

        this.loading = false
    }

    private async sendActivationEmail () {
        try {
            await sendActivationEmail()
            this.$root.$vsToast({
                heading: 'Email inviata con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.disableEmailButton()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'invio dell\'email di attivazione',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    async disableEmailButton () {
        this.emailBtnEnabled = false

        let count = 60
        const timer = setInterval(() => {
            this.count = count--
            if (count === 1) {
                clearInterval(timer)
                this.emailBtnEnabled = true
            }
        }, 1000)
    }
}
